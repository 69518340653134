import React from 'react'
import styles from './AnnotationsList.module.css'
import features from '../data/features.json'

export default function AnnotationsList(props) {

	const { hlpMapFeatureAnnotations, authUser } = props

	if (!hlpMapFeatureAnnotations) {
		return null
	}

	const annotations = []
	for (let uid in hlpMapFeatureAnnotations) {
		if (uid !== authUser.uid) {
			annotations.push(hlpMapFeatureAnnotations[uid])
		}
	}

	if (annotations.length === 0) {
		return null
	}
	return (
		<div className={styles.display}>
			<h3>Other Annotations</h3>

			{annotations.map(annotation => {
				let date;
				if(annotation.data.timestamp){
					date = new Date(annotation.data.timestamp).toLocaleDateString(
						'en-gb',
						{
							year: 'numeric',
							month: 'short',
							day: 'numeric'
						}
					);
				}



				console.log(features)
				let labels = [];

				const featureA = annotation.data.featureA;
				const featureB = annotation.data.featureB;
				const featureC = annotation.data.featureC;
				const featureD = annotation.data.featureD;

				if (featureA && featureB && featureC && featureD) {

					const labelA = features[featureA].label_en;
					const labelB = features[featureA].features[featureB].label_en;
					const labelC = features[featureA].features[featureB].features[featureC].label_en;
					const labelD = features[featureA].features[featureB].features[featureC].features[featureD].label_en;

					labels = [labelA, labelB, labelC, labelD]

				} else if (featureA && featureB && featureC) {

					const labelA = features[featureA].label_en;
					const labelB = features[featureA].features[featureB].label_en;
					const labelC = features[featureA].features[featureB].features[featureC].label_en;

					labels = [labelA, labelB, labelC]

				} else if (featureA && featureB) {

					const labelA = features[featureA].label_en;
					const labelB = features[featureA].features[featureB].label_en;

					labels = [labelA, labelB]

				} else if (featureA) {

					const labelA = features[featureA].label_en;
					
					labels = [labelA]
				}


				return (
					<div className={styles.row} key={`annotation_list_item_${annotation.data.uid}`}>
						<div className={styles.date}>annnotated on {date}</div>

						{labels.map((label, idx) => (
							<span key={`label_${label}_${idx}`}>

								{label}	
								{idx < labels.length -1 && <span> &rarr; </span>}
							</span>
						))}

						<div className={styles.tags}>
							{annotation.data.age && <span className={styles.tag}>Age: {annotation.data.age}</span>}
							{annotation.data.condition && <span className={styles.tag}>Condition: {annotation.data.condition}</span>}
						</div>
					</div>
				)}
			)}
		</div>
	)
}


/*
const groupLabel = featureGroups[annotation.data.featureGroup].name
const subGroupLabel = featureGroups[annotation.data.featureGroup].sections[annotation.data.featureSubGroup || 'list'].section
const itemLabel = featureGroups[annotation.data.featureGroup].sections[annotation.data.featureSubGroup || 'list'].data[`${annotation.data.featureGroup}_${annotation.data.featureItem}`].label
const labels = [groupLabel, subGroupLabel, itemLabel].filter(label => label !== 'List')
*/