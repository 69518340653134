import React, { Component } from 'react'

import mapboxgl, { setRTLTextPlugin, LngLatBounds } from 'mapbox-gl';
import { Redirect, Link } from 'react-router-dom'

import 'mapbox-gl/dist/mapbox-gl.css'

import MapboxDraw from "@mapbox/mapbox-gl-draw";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import drawStyles from '../data/draw-styles.json'

import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

import _ from 'lodash'


import style from './MapTool.module.css'
import LayerToggles from './LayerToggles'
// import MapDrawingTool from './MapDrawingTool'

import FeatureForm from './FeatureForm'
import AnnotationForm from './AnnotationForm'
import AnnotationsList from './AnnotationsList'
import MapLegend from './MapLegend'
import {
	fetchFeatures,
	addFeature,
	deleteFeature,
	addAnnotation,
	updateAnnotation,
} from '../actions'

import ToolTip from './ToolTip';
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
mapboxgl.accessToken = 'pk.eyJ1IjoiYW5lY2RvdGUxMDEiLCJhIjoiY2oxMGhjbmpsMDAyZzJ3a2V0ZTBsNThoMiJ9.1Ce55CnAaojzkqgfX70fAw'

class MapTool extends Component {
	constructor(props) {
		super(props)
		this.state = {
		}

		this.fetchData = this.fetchData.bind(this)
		this.setCurrentFeature = this.setCurrentFeature.bind(this)
		this.handleFeatureFormSubmit = this.handleFeatureFormSubmit.bind(this)
		this.handleAnnotationFormSubmit = this.handleAnnotationFormSubmit.bind(this)
		this.handleFeatureDelete = this.handleFeatureDelete.bind(this)
		this.updateSelectedFeature = this.updateSelectedFeature.bind(this)
		this.drawModeChange = this.drawModeChange.bind(this)
		this.updateFeatures = this.updateFeatures.bind(this)
		this.forwardGeocoder = this.forwardGeocoder.bind(this)
		this.storeMapPosition = this.storeMapPosition.bind(this)
		
	}

	componentDidMount() {

		let mapPosition = null
		
		let lat = 36.5847942
		let lng = 37.047591
		let zoom = 14.5

		try {
			mapPosition = JSON.parse(localStorage.getItem('map'));
			if (mapPosition) {
				lat = mapPosition.center.lat
				lng = mapPosition.center.lng
				zoom = mapPosition.zoom
			}
		} catch (error) {
			console.error(error)
		}

		this.mapboxMap = new mapboxgl.Map({
			container: 'map',
			style: 'mapbox://styles/anecdote101/ckk3v3br94swg17t8dtu78eqi?fresh=true',
			center: [lng, lat],
			zoom: [zoom],
			attributionControl: false,
			zoomControl: true,
			interactive: true,
			flyToOptions: {
				zoom: 9,
				speed: 0.2,
				curve: 1,
			},
			movingMethod: 'jumpTo'
		})

		this.mapboxDraw = new MapboxDraw({
			styles: drawStyles,
			boxSelect: false,
		})
		
		this.mapboxMap.on('click', e=>{

			if(this.state.currentOtherUsersFeature){
				this.mapboxMap.setFeatureState(
					{ source: 'other_user_features_source', id: this.state.currentOtherUsersFeature },
					{ active: false }
				);	
			}
			

			if(this.mapboxDraw.getMode() === 'simple_select'){
				var features = this.mapboxMap.queryRenderedFeatures(e.point, {layers: ['azaz-streets']})

				if (typeof this.mapboxMap.getLayer('current_selected_road') !== "undefined" ){
					this.mapboxMap.removeLayer('current_selected_road')					
				}
				if (typeof this.mapboxMap.getLayer('current_selected_road_label') !== "undefined" ){
					this.mapboxMap.removeLayer('current_selected_road_label')
				}
				if (typeof this.mapboxMap.getSource('current_selected_road_source') !== "undefined" ){
					this.mapboxMap.removeSource('current_selected_road_source')
				}
				if(features && features.length > 0){

					const roadFeatureId = features[0].id
					let result = [];

					if(this.props.currentUserFeatureCollection && this.props.currentUserFeatureCollection.features){
						result = this.props.currentUserFeatureCollection.features.filter(feature => {
							return feature.id === roadFeatureId
						})						
					}

					if(result.length === 0){
						this.setCurrentFeature(features[0])
						this.mapboxMap.addSource('current_selected_road_source', {
							"type":"geojson",
							"data": features[0].toJSON()
						})
						this.mapboxMap.addLayer({
							"id": "current_selected_road",
							"type": "line",
							"source": "current_selected_road_source",
							"layout": {
								"line-join": "round",
								"line-cap": "round"
							},
							"paint": {
								"line-color": "#00ff00",
								"line-width": 8
							}
						})
					}
					
				} else {
					this.setCurrentFeature(null)
				}
			}
		})

		this.mapboxMap.addControl(
			new MapboxGeocoder({
				accessToken: mapboxgl.accessToken,
				localGeocoder: this.forwardGeocoder,
				mapboxgl: mapboxgl
			})
		)
		this.mapboxMap.addControl(new mapboxgl.NavigationControl(), 'bottom-right');


		this.mapboxMap.addControl(this.mapboxDraw, 'top-left')
		this.mapboxMap.on('draw.create', this.updateSelectedFeature)
		this.mapboxMap.on('draw.update', this.updateSelectedFeature)
		this.mapboxMap.on('draw.selectionchange', this.updateSelectedFeature)
		this.mapboxMap.on('draw.modechange', this.drawModeChange)

		this.mapboxMap.on('load', this.fetchData)
		this.mapboxMap.on('zoomend', this.storeMapPosition)
		this.mapboxMap.on('dragend', this.storeMapPosition)
	}

	drawModeChange(){
		this.setState({
			drawMode: this.mapboxDraw.getMode(),
		})	
	}

	storeMapPosition() {

		const mapPosition = {
			zoom: this.mapboxMap.getZoom(),
			center: this.mapboxMap.getCenter(),
		}

		localStorage.setItem('map', JSON.stringify(mapPosition));
	}

	componentDidUpdate(prevProps) {
		
		// this.mapboxDraw.getAll().features.length < Object.keys(this.props.hlpMapFeatures).length
		
		if (Object.keys(prevProps.hlpMapFeatures).length !== Object.keys(this.props.hlpMapFeatures).length || this.mapboxDraw.getAll().features.length === 0) {	
			this.updateFeatures()
		}

		if (prevProps.savingAnnotation !== this.props.savingAnnotation && this.props.savingAnnotation === false) {
			
			this.setState({
				saved: true,
			})

			setTimeout(() => {
				this.setState({
					saved: false,
				})				
			}, 1000)
			this.updateFeatures()
		}
	
		if (prevProps.deletingAnnotation !== this.props.deletingAnnotation && this.props.deletingAnnotation === false) {

			this.updateFeatures()
		}
	}

	forwardGeocoder(query) {

		const matchingFeatures = [];

		for (let hlpMapFeatureId in this.props.hlpMapAnnotations) {
			for (let uid in this.props.hlpMapAnnotations[hlpMapFeatureId]) {
				const hlpMapAnnotation = this.props.hlpMapAnnotations[hlpMapFeatureId][uid]

				const matchEN = (hlpMapAnnotation.data.nameEN.toLowerCase().search(query.toLowerCase()) !== -1)
				const matchAR = (hlpMapAnnotation.data.nameAR.toLowerCase().search(query.toLowerCase()) !== -1)


				const names = []
				const descriptions = []

				if (hlpMapAnnotation.data.nameEN) {
					names.push(hlpMapAnnotation.data.nameEN)
				}
				if (hlpMapAnnotation.data.nameAR) {
					names.push(hlpMapAnnotation.data.nameAR)
				}

				if (hlpMapAnnotation.data.descriptionEN) {
					descriptions.push(hlpMapAnnotation.data.descriptionEN)
				}
				if (hlpMapAnnotation.data.descriptionAR) {
					descriptions.push(hlpMapAnnotation.data.descriptionAR)
				}

				const hlpMapFeature = this.props.hlpMapFeatures[hlpMapFeatureId]


				let center

				if (hlpMapFeature.data.geoJSON.geometry.type === 'Polygon') {
					center = hlpMapFeature.data.geoJSON.geometry.coordinates[0][0];
				} else if (hlpMapFeature.data.geoJSON.geometry.type === 'LineString') {
					center = hlpMapFeature.data.geoJSON.geometry.coordinates[0]
				} else if (hlpMapFeature.data.geoJSON.geometry.type === 'Point') {
					center = hlpMapFeature.data.geoJSON.geometry.coordinates
				}

				
				if(hlpMapFeature.data.geoJSON.type !== 'Feature'){					
					center = hlpMapFeature.data.geoJSON.geometry.coordinates[0];
				}

				if (matchEN || matchAR) {
					const feature = {
						'type': 'Feature',
						'properties': {
							'title': names.join(' - '),
							'description': descriptions.join(' - ')
						},
						'geometry': {
							'coordinates': center,
							'type': 'Point'
						},
						place_name: '📍 ' + names.join(' - '),
						center: center,
						place_type: [hlpMapAnnotation.data.featureGroup],
					}


					matchingFeatures.push(feature)
				}
			}

		}

		return matchingFeatures
	}

	updateFeatures() {



		this.props.otherUsersFeatureCollection.features.forEach((feature, i)=>{
			feature.id = i;
		});

		if (typeof this.mapboxMap.getSource('other_user_features_source') === "undefined" ){
			this.mapboxMap.addSource('other_user_features_source', {
				"type":"geojson",
				"data": this.props.otherUsersFeatureCollection
			})
		}

		if (typeof this.mapboxMap.getLayer('other_user_features') === "undefined" ){
			this.mapboxMap.addLayer({
				"id": "other_user_features",
				"type": "fill",
				"source": "other_user_features_source",
				"paint": {
					"fill-color": "#f44336",
					'fill-opacity': [
					'case',
						['boolean', ['feature-state', 'active'], false],
						0.8,
						0.5
					]
				}
			})
		}

		if (typeof this.mapboxMap.getLayer('other_user_features_stroke') === "undefined" ){
			this.mapboxMap.addLayer({
				"id": "other_user_features_stroke",
				"type": "line",
				"source": "other_user_features_source",

				"layout": {
					"line-cap": "round",
					"line-join": "round"
				},
				"paint": {
					"line-color": "#f44336",
					"line-width": [
						"interpolate",
						["linear", 1],
						["zoom"],
						15,
						1,
						18,
						3
					]
				}
			});

			this.mapboxMap.on('click', 'other_user_features', e=> {
				if(e.features.length > 0){
					this.setCurrentFeature(e.features[0]);
					this.setState({
						currentOtherUsersFeature: e.features[0].id
					})
					this.mapboxMap.setFeatureState(
						{ source: 'other_user_features_source', id: e.features[0].id },
						{ active: true }
					);					
				}
			});
		}

		

		

		

		


		if (this.props.currentUserFeatureCollection.features.length >= 0) {
			this.mapboxDraw.set(this.props.currentUserFeatureCollection);
		}	

		const roads = []
		const hiddenRoads = [];
		this.props.currentUserFeatureCollection.features.forEach(feature=>{

			if(feature.geometry.type === 'LineString'){
				roads.push(feature)
			}
			if(feature.properties['@id'] && feature.geometry.type === 'LineString' && hiddenRoads.indexOf(feature.properties['@id']) === -1){
				hiddenRoads.push(feature.properties['@id'])
			}
		});
		if (typeof this.mapboxMap.getLayer('custom_road_labels') !== "undefined" ){
			this.mapboxMap.removeLayer('custom_road_labels')
		}
		if (typeof this.mapboxMap.getSource('custom_road_labels_source') !== "undefined" ){
			this.mapboxMap.removeSource('custom_road_labels_source')
		}
		this.mapboxMap.addSource('custom_road_labels_source', {
			"type":"geojson",
			"data": {
				'type': 'FeatureCollection',
				'features': roads
			}
		})

		this.mapboxMap.addLayer({
			"id": "custom_road_labels",
			"type": "symbol",
			"source": "custom_road_labels_source",
			"layout": {
				"symbol-placement": "line",
				"text-field": '{name}',
				"text-size": [
					"interpolate",
					["linear", 1],
					["zoom"],
					14,
					5,
					18,
					12
				],
				"text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
				"text-letter-spacing": 0.05
			},
			"paint": {
				"text-color": "#003800",
				"text-halo-color": "rgba(0,255,0,0.6)",
				"text-halo-width": 1
			}
		}, '')

		if (this.state.currentFeature) {
			this.setCurrentFeature(this.mapboxDraw.get(this.state.currentFeature.id))			
		}
		
		if (hiddenRoads.length > 0) {
			this.mapboxMap.setFilter('azaz-streets', [
				'match',
				['get', '@id'],
				hiddenRoads,
				false,
				true
			]);
		}
	}	

	fetchData() {
		
		this.props.dispatch(fetchFeatures(this.props.db))
	}

	updateSelectedFeature(e) {
		const selected = this.mapboxDraw.getSelected();		
		if (selected && selected.features.length === 1) {
			const feature = selected.features[0]
			this.setCurrentFeature(feature)
		} else {
			this.setCurrentFeature(null)
		}
	}

	setCurrentFeature(currentFeature) {
		if (currentFeature !== undefined) {
			this.setState({
				currentFeature: currentFeature,
			})
		}	
	}

	handleFeatureFormSubmit(e) {
		e.preventDefault();
		this.props.dispatch(addFeature(this.props.db, {
			geoJSON: JSON.stringify(this.state.currentFeature),
			uid: this.props.authUser.uid,			
		}))
	}		

	handleAnnotationFormSubmit(hlpMapFeatureId, hlpMapAnnotationId, values) {

		const canModifyGeometry = (this.state.currentFeature.properties.currentUserCreated && (!this.props.hlpMapAnnotations[hlpMapFeatureId] || Object.keys(this.props.hlpMapAnnotations[hlpMapFeatureId]).length === 1));

		if (hlpMapAnnotationId) {
			const params = {
				hlpMapAnnotationId,
				...values
			}

			if (canModifyGeometry) {
				params.geometry = this.state.currentFeature.geometry
			}

			this.props.dispatch(updateAnnotation(this.props.db, params))			
		} else {

			const params = {
				uid: this.props.authUser.uid,
				hlpMapFeatureId,
				...values
			}

			if (canModifyGeometry) {
				params.geometry = this.state.currentFeature.geometry
			}
			
			this.props.dispatch(addAnnotation(this.props.db, params))			
		}
	}

	handleFeatureDelete(hlpMapFeatureId, hlpMapAnnotationId) {
		this.props.dispatch(deleteFeature(this.props.db, { hlpMapFeatureId, hlpMapAnnotationId }))	
		this.setState({
			currentFeature: null,
		})
	}

	render() {
		
		if (!this.props.authUser) {
			return <Redirect to="/login" />
		}

		let userAnnotation
		let hlpMapFeatureAnnotations
		let geometryType
		let highwayRoadType
		let deletable

		if (this.state.currentFeature) {
			geometryType = this.state.currentFeature.geometry.type
			hlpMapFeatureAnnotations = this.props.hlpMapAnnotations[this.state.currentFeature.properties.hlpMapFeatureId]
			if (hlpMapFeatureAnnotations) {
			 	userAnnotation = hlpMapFeatureAnnotations[this.props.authUser.uid]
			}

			if (this.state.currentFeature.properties.highway) {
				highwayRoadType = this.state.currentFeature.properties.highway
			}

			deletable = (this.state.currentFeature.properties.currentUserCreated && (!hlpMapFeatureAnnotations || Object.keys(hlpMapFeatureAnnotations).length === 1));
		}

		let toolTip = null
		if (['draw_line_string', 'draw_polygon', 'draw_point'].indexOf(this.state.drawMode) >= 0) {
			toolTip = <ToolTip mode={this.state.drawMode} />
		}

		return (
			<div className={style.display}>				
				<div className={style.map}>
					
					<div id="map" className={style.map}>
					</div>

					<div className={style.sidebar}>
						<MapLegend/>

						{toolTip}

						<LayerToggles mapboxMap={this.mapboxMap}/>
						{this.state.currentFeature && !this.state.currentFeature.properties.hlpMapFeatureId && 
							<FeatureForm onSubmit={this.handleFeatureFormSubmit}/>
						}

						{this.state.currentFeature && this.state.currentFeature.properties.hlpMapFeatureId && 
							<React.Fragment>
								<AnnotationForm 
									key={(userAnnotation) ? `annotation_form_${userAnnotation.doc.id}` : 'annotation_form_new'}
									hlpMapFeatureId={this.state.currentFeature.properties.hlpMapFeatureId} 
									geometryType={geometryType} 
									highwayRoadType={highwayRoadType} 
									hlpMapAnnotation={userAnnotation} 
									deletable={deletable}
									saving={this.props.savingAnnotation}
									saved={this.state.saved}
									deleting={this.props.deletingAnnotation}
									onDelete={this.handleFeatureDelete}
									onSubmit={this.handleAnnotationFormSubmit}
								/>
								<AnnotationsList hlpMapFeatureAnnotations={hlpMapFeatureAnnotations} authUser={this.props.authUser} />
							</React.Fragment>
						}

					</div>
				</div>
			</div>
		)
	}
}

export default MapTool