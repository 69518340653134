import React, { useState, useEffect, useRef } from 'react';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import {
  BrowserRouter as Router,
  Redirect,
  Route,
} from "react-router-dom";

import Home from './components/Home'
import Login from './components/Login'
import Join from './components/Join'
import WhoWeAre from './components/WhoWeAre'
import Nav from './components/Nav'

import TermsAndConditions from './components/TermsAndConditions'
import PrivacyPolicy from './components/PrivacyPolicy'
import FAQ from './components/FAQ'
import VerifyEmail from './components/VerifyEmail'

import HLPMapper from './containers/HLPMapper'

const firebaseConfig = {
	apiKey: "AIzaSyDkW73GBi_CO1NxtbSFqlaie7wM6TW5DQc",
	authDomain: "hlp-maps-dev.firebaseapp.com",
	databaseURL: "https://hlp-maps-dev-default-rtdb.europe-west1.firebasedatabase.app",
	projectId: "hlp-maps-dev",
	storageBucket: "hlp-maps-dev.appspot.com",
	messagingSenderId: "567072847167",
	appId: "1:567072847167:web:98e55af1c0347593014f8a"
};

firebase.initializeApp(firebaseConfig);
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)

const db = firebase.firestore();


// const firebaseApp = firebase.initializeApp(firebaseConfig);
// const firebaseAppAuth = firebaseApp.auth();

// const history = useHistory();
// 			// history.push('/map');

const App = () => {

	const [authUser, setAuthUser] = useState(undefined)
	const [language, setLanguage] = useState('en')

	firebase.auth().onAuthStateChanged(user => {
		if (user) {
			setAuthUser(user)
		} else {
			setAuthUser(null)
		}
	})

	const isEmailVerified = () => {
		let isFacebook = false
		authUser.providerData.forEach(provider => {
			 if (provider.providerId === 'facebook.com') {
			 	isFacebook = true
			 }
		})
		
		return (authUser.emailVerified || isFacebook)
	}

	const logout = (e) => {
		firebase.auth().signOut().then(() => {
			
		}).catch((error) => {
			console.error(error)
		});
	}

	const handleLanguageChange = language => {
		setLanguage(language);
	}

	const handleResendVerification = () => {
		authUser.sendEmailVerification();
	}

	if (authUser === undefined) {
		return null
	}

	// console.log(authUser)
	return (
		<Router>

			<div className="container">
				<Nav authUser={authUser} language={language} onLogout={logout} onLanguageChange={handleLanguageChange} />

				<Route exact path="/">
					<Home language={language} />
				</Route>
				<Route exact path="/map">
					{authUser && isEmailVerified() && (
						<HLPMapper db={db} authUser={authUser} />
					)}

					{authUser && !isEmailVerified() && (
						<Redirect to="/verify-email" />
					)}
					
					{!authUser && (
						<Redirect to="/login" />
					)}
				</Route>
				
				<Route exact path="/login">
					<Login firebase={firebase} authUser={authUser} onLogout={logout} isEmailVerified={isEmailVerified}/>
				</Route>
				<Route exact path="/join">
					<Join language={language} />
				</Route>
				<Route exact path="/who-we-are">
					<WhoWeAre language={language} />
				</Route>
				<Route exact path="/terms-and-conditions">
					<TermsAndConditions />
				</Route>
				<Route exact path="/privacy-policy">
					<PrivacyPolicy />
				</Route>
				<Route exact path="/faq">
					<FAQ />
				</Route>


				<Route exact path="/verify-email">
					{authUser && (
						<VerifyEmail onResendEmail={handleResendVerification} onLogout={logout} />
					)}
					{!authUser && (
						<Redirect to="/login" />
					)}
				</Route>
			</div>
		</Router>		
	)
}

export default App