import React from 'react'
import section from './Section.module.css';
import hero from '../images/hero-2.png'

export default function WhoWeAre(props) {
	return (
		<section dir="auto">
			<div className={section.hero}>
				<img src={hero} alt="map" />
			</div>

			{props.language === 'en' && <WhoWeAreEn />}
			{props.language === 'ar' && <WhoWeAreAr />}

		</section>
	)
}


function WhoWeAreEn() {
	return (
		<React.Fragment>
			<article>
				<h1>Our Story</h1>
				<p>HLP Maps is a UK registered CIC founded in 2020 by two young social entrepreneurs Shahd Mousalli and Tameem Emam. The idea behind HLPM came when we were introduced to collective digital mapping on OSM as a tool for humanitarian intervention and putting marginalised communities on the map. The aim was to enable aid and development actors to reach them and work with them. We adopted the creative approach of digital maps to be used in fragile and (post) conflict contexts, to document the rights for people and communities whose properties are under threat.</p>
				<p>HLP Maps’ work is governed by human rights principles identifying HLP rights as human rights. Our mission is to provide and facilitate a creative and participatory intervention through digital maps in response to conflict-driven forced evictions while centring the knowledge and agency of affected communities, largely IDPs and Refugees.</p>
			</article>
			<article>
				<h1>Methodology</h1>
				<p>Create the map – claim your property – verify your neighbours.</p>
				<p>HLP Maps provide an interactive platform that hosts different satellite images of targeted areas to help affected communities identify the damages in buildings or pre-destruction buildings. The platform will have the tools to map and digitize the satellite imagery to make the city easier for claimants to navigate.</p>
				<p>The second stage would be when claimants/refugees/IDPs are able to detect their property easily and have the option of filling a form stating information about their property, the form includes info such as: area, the physical status, date of ownership, eviction date, Etc. This information will be securely saved and added to a larger set of data, in order to pursue legal action for restitution.</p>
				<p>The verification process comes when the claimants claim their property, they will be asked to verify the names of their neighbours, or their property will be verified by the local community, Mukhtar, or community mobiliser.</p>
			</article>
			<article>
				<h1>Founders</h1>
				<p>Tameem Emam is a civil engineer and urban practitioner, based in the UK. Tameem holds an MSc in Urban Planning Development from University College London. Tameem worked with many international organizations, inside and outside Syria, on projects supporting rights to proper housing and shelters for IDPs, and rights to Internet and proper information in camps.</p>
				<p>Shahd Mousalli Is a UK based peacebuilding and development practitioner currently working in the charity sector particularly on rights, resilience, and response in fragile and conflict contexts. Shahd holds a master’s degree in Gender and International Deployment from the University of Warwick.  As an asylum seeker herself, Shahd actively works with refugee and migrants’ communities in the UK to promote their rights and resist the hostile environment policies and discourse on migrants and refugees. Shahd takes an intersectional and de-colonial feminist approach to social justice.</p>
			</article>
		</React.Fragment>
	)
}

function WhoWeAreAr() {
	return (
		<React.Fragment>
			<article>
				<h1>قصتنا</h1>
				<p>خرائط الملكية العقارية هي شركة غير ربحية مسجلة في المملكة المتحدة تأسست عام 2020 من قبل اثنين من رواد الأعمال الاجتماعيين، شهد موصلي وتميم إمام. قدمت فكرة استخدام الخرائط عندما تعرفنا على فريق الخرائط الرقمية الجماعية كأداة للتدخل الإنساني ووضع المجتمعات المهمشة على الخريطة. كان الهدف هو تمكين الجهات الفاعلة في مجال المساعدة الطبية والتنمية من الوصول إليهم والعمل معهم. اعتمدنا النهج الإبداعي للخرائط الرقمية لاستخدامها في سياقات الملكية الضعيفة وسياق ما بعد الصراع، لتوثيق حقوق الأشخاص والمجتمعات التي تتعرض ممتلكاتها للتهديد.</p>
				<p>يخضع عمل خرائط الملكية العقارية لمبادئ حقوق الإنسان التي تنص على أن حقوق الإسكان والأرض والملكية هي من حقوق الإنسان. مهمتنا هي توفير وتسهيل تدخل إبداعي وتشاركي من خلال الخرائط الرقمية استجابةً لعمليات الإخلاء القسري الناجمة عن النزاع مع التركيز على توعية المجتمعات المتضررة، ومعظمهم من النازحين واللاجئين، ومساعدتهم في اكتساب الوكالة لأنفسهم في التصرف في ممتلكاتهم. </p>
			</article>
			<article>
				<h1>المنهجية</h1>
				<p>أنشئ الخريطة - طالب بممتلكاتك - تحقق من جيران.</p>
				<p>توفر خرائط الملكية العقارية منصة تفاعلية تستضيف صور الأقمار الصناعية المختلفة للمناطق المستهدفة، لمساعدة المجتمعات المتضررة على تحديد الأضرار في المباني أو تحديد المباني ما قبل التدمير. ستحتوي المنصة على الأدوات اللازمة لرسم خريطة صور القمر الصناعي ورقمنتها لتسهيل التنقل في المدينة على المطالبين.</p>
				<p>ستكون المرحلة الثانية عندما يتمكن المطالبون / اللاجئون / النازحون داخليًا من اكتشاف ممتلكاتهم بسهولة ويكون لديهم خيار ملء نموذج يوضح معلومات حول ممتلكاتهم، ويتضمن النموذج معلومات مثل: المساحة، والوضع الفيزيائي للمبنى، وتاريخ الملكية، وتاريخ الإخلاء، إلخ. سيتم حفظ هذه المعلومات بشكل آمن وإضافتها إلى مجموعة أكبر من البيانات، من أجل متابعة الإجراءات القانونية في المستقبل عند إعادة الحقوق لأصحابها. </p>
				<p>تأتي عملية التحقق عندما يطالب المطالبون بممتلكاتهم، سيُطلب منهم التحقق من أسماء جيرانهم، كما سيتم التحقق من ادعاءاتهم من قبل المجتمع المحلي أو المختار أو الناشط المجتمعي المسؤول عن تلك المنطقة.</p>
			</article>
			<article>
				<h1>المؤسسون</h1>
				<p>تميم إمام مهندس مدني ومخطط عمراني، مقيم في المملكة المتحدة. حاصل على شهادة ماجستير في تطوير التخطيط العمراني من جامعة لندن. عمل مع العديد من المنظمات الدولية، داخل سوريا وخارجها، على مشاريع تدعم حقوق السكن اللائق والملاجئ للنازحين، والحق في الإنترنت والوصول إلى المعلومات في المخيمات. يعمل حالياً كمدير لهذا المشروع واستشارياً مع شركات ومنظمات في بريطانيا. </p>
				<p>شهد موصلي مؤسسة ومديرة شريكة تعمل في مجال بناء السلام والتنمية، وتركز نشاطها في بلدان النزاع. حاصلة على درجة الماجستير في الجندر والتنمية الدولية من جامعة (Warwick). من تجربتها كلاجئة في المملكة المتحدة، تنشط شهد مع مجتمعات اللاجئين/ات والمهاجرين/ات من خلال حملات المناصرة ومقاومة السياسات المجحفة بحقوق مجتمعات الهجرة واللجوء والخطابات المعادية لوجودها وتتخذ لنشاطها نهجا نسوياً تقاطعياً ومعارضاً للعنصرية في سبيل تحقيق العدالة الاجتماعية. </p>
			</article>
		</React.Fragment>
	)
}














  






















