import React, { useState } from 'react'
import style from './MapLegend.module.css';




export default function MapLegend(props) {

	

	

	return (
		<div className={style.layerControls}>
			<div>
				<div className={style.row}>
					<div><div className={style.line} style={{backgroundColor: 'fuchsia'}}></div></div>
					OSMap Roads (click to edit)
				</div>
				<div className={style.row}>
					<div><div className={style.line} style={{backgroundColor: '#00ff00'}}></div></div>
					User Drawn/Edited Roads
				</div>
				<div className={style.row}>
					<div><div className={style.building}></div></div>
					Your Features
				</div>
				<div className={style.row}>
					<div><div className={style.otherBuilding}></div></div>
					Other Users’ Features
				</div>
			</div>
		</div>
	)	
}