import {
	HLP_MAP_FEATURES_SUCCESS,
	HLP_MAP_FEATURE_DELETE_REQUEST,
	HLP_MAP_FEATURE_DELETE_SUCCESS,
	HLP_MAP_FEATURE_ADD_SUCCESS,

	HLP_MAP_ANNOTATION_ADD_REQUEST,
	HLP_MAP_ANNOTATION_UPDATE_REQUEST,
	HLP_MAP_ANNOTATION_ADD_SUCCESS,
	HLP_MAP_ANNOTATION_UPDATE_SUCCESS,

} from '../actions'

export default function reducer(
	state = {
		hlpMapFeatures: {},
		hlpMapAnnotations: {},
		savingAnnotation: false,
		deletingAnnotation: false,
	},
	action
) {
	
	switch (action.type) {
	case HLP_MAP_FEATURES_SUCCESS: {

		const hlpMapFeatures = {}

		action.response.features.forEach(doc => {
			const data = doc.data()
			data.geoJSON = JSON.parse(data.geoJSON)
			hlpMapFeatures[doc.id] = {
				data: data,
				doc: doc,
			}
		})

		const hlpMapAnnotations = {}

		action.response.annotations.forEach(doc => {
			const data = doc.data()
			if (!hlpMapAnnotations[data.hlpMapFeatureId]) {
				hlpMapAnnotations[data.hlpMapFeatureId] = {}
			}

			hlpMapAnnotations[data.hlpMapFeatureId][data.uid] = {
				data: data,
				doc: doc,
			}
		})

		return Object.assign({}, state, {
			hlpMapFeatures: hlpMapFeatures,
			hlpMapAnnotations: hlpMapAnnotations,
		})
	}
	case HLP_MAP_FEATURE_ADD_SUCCESS: {

		const doc = action.response
		const data = doc.data()
		data.geoJSON = JSON.parse(data.geoJSON)

		const hlpMapFeatures = Object.assign({}, state.hlpMapFeatures, {
			[action.response.id]: {
				data: data,
				doc: doc,				
			}
		})

		return Object.assign({}, state, {
			hlpMapFeatures: hlpMapFeatures,
		})
	}	


	case HLP_MAP_FEATURE_DELETE_SUCCESS: {
		console.log(action)

		const hlpMapAnnotations = Object.assign({}, state.hlpMapAnnotations)
		const hlpMapFeatures = Object.assign({}, state.hlpMapFeatures)

		delete hlpMapFeatures[action.response.hlpMapFeatureId]
		delete hlpMapAnnotations[action.response.hlpMapAnnotationId]

		return Object.assign({}, state, {
			hlpMapAnnotations: hlpMapAnnotations,
			hlpMapFeatures: hlpMapFeatures,
			deletingAnnotation: false,
		})

		// const doc = action.response
		// const data = doc.data()
		// data.geoJSON = JSON.parse(data.geoJSON)

		// const hlpMapFeatures = Object.assign({}, state.hlpMapFeatures, {
		// 	[action.response.id]: {
		// 		data: data,
		// 		doc: doc,				
		// 	}
		// })

		// return Object.assign({}, state, {
		// 	hlpMapFeatures: hlpMapFeatures,
		// })
	}	



	case HLP_MAP_ANNOTATION_ADD_REQUEST:
	case HLP_MAP_ANNOTATION_UPDATE_REQUEST: {
		return Object.assign({}, state, {
			savingAnnotation: true,
		})
	}
	case HLP_MAP_FEATURE_DELETE_REQUEST: {
		return Object.assign({}, state, {
			deletingAnnotation: true,
		})
	}


	case HLP_MAP_ANNOTATION_ADD_SUCCESS:
	case HLP_MAP_ANNOTATION_UPDATE_SUCCESS: {

		const annotationDoc = action.response.annotation
		const annotationData = annotationDoc.data()

		const hlpMapAnnotations = Object.assign({}, state.hlpMapAnnotations)
		if (!hlpMapAnnotations[annotationData.hlpMapFeatureId]) {
			hlpMapAnnotations[annotationData.hlpMapFeatureId] = {}
		}

		hlpMapAnnotations[annotationData.hlpMapFeatureId][annotationData.uid] = {
			data: annotationData,
			doc: annotationDoc,
		}

		const featureDoc = action.response.feature
		const featureData = featureDoc.data()
		featureData.geoJSON = JSON.parse(featureData.geoJSON)

		const hlpMapFeatures = Object.assign({}, state.hlpMapFeatures, {
			[featureDoc.id]: {
				data: featureData,
				doc: featureDoc,				
			}
		})

		return Object.assign({}, state, {
			hlpMapAnnotations: hlpMapAnnotations,
			hlpMapFeatures: hlpMapFeatures,
			savingAnnotation: false,
		})
	}

	default:
		return state
	}
}
