import React from 'react'
import section from './Section.module.css';
import hero from '../images/hero-5.png'
import forms from './Forms.module.css'
import { Redirect, Link } from 'react-router-dom'

export default function VerifyEmail(props) {
	return (
		<section>
			<div className={section.hero}>
				<img src={hero} alt="hero" />
			</div>
			<article>
				<h2>Thank You</h2>
				<p>A verification has email has been sent to the address provided. Once you have clicked the verification link you can <a href="/map">start mapping</a>.</p>
				<div>
					<button className={forms.buttonElement} onClick={() => window.location = '/map'}>Start Mapping</button>
					<button className={forms.buttonElement} onClick={props.onResendEmail}>Resend Verifcation Email</button>
					<button className={forms.buttonElement} onClick={props.onLogout}>Logout</button>
				</div>
			</article>
		</section>
	)
}
