import React from 'react'
import style from './AnnotationForm.module.css';
import forms from './Forms.module.css';

export default function FeatureForm(props) {
	return (
		<div className={style.form}>
			<form onSubmit={props.onSubmit}>		
				<h3>New Feature</h3>
				<p>Save this feature to add an annotation</p>
				<div className={style.action}>
					<button 
						className={`${forms.buttonElement} ${forms.save}`} 
						type="submit"
					>
						<i className="far fa-save"></i>Save
					</button>
				</div>
			</form>
		</div>
	)
}
