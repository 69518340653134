async function callApi(db, action, params) {

	if (action === 'hlp_map_features_fetch') {
		
		const hlpMapFeatures = await db.collection('hlpMapFeatures').get()
		const hlpMapAnnotations = await db.collection('hlpMapAnnotations').get()

		return {
			features: hlpMapFeatures,
			annotations: hlpMapAnnotations,
		}

	} else if (action === 'hlp_map_feature_add') {

		const hlpMapFeature = await db.collection('hlpMapFeatures').add(params)
		
		const geoJSON = JSON.parse(params.geoJSON)
		geoJSON.properties.hlpMapFeatureId = hlpMapFeature.id

		await db.collection('hlpMapFeatures').doc(hlpMapFeature.id).update({ geoJSON: JSON.stringify(geoJSON) });
		return await db.collection('hlpMapFeatures').doc(hlpMapFeature.id).get()

	} else if (action === 'hlp_map_feature_delete') {

		await db.collection('hlpMapFeatures').doc(params.hlpMapFeatureId).delete()
		if (params.hlpMapAnnotationId) {
			await db.collection('hlpMapAnnotations').doc(params.hlpMapAnnotationId).delete()
		}
		return params

	} else if (action === 'hlp_map_annotation_add') {

		let geometry
		if (params.geometry) {
			geometry = params.geometry
			delete params.geometry
		}

		params.timestamp = new Date().getTime()
		const hlpMapAnnotationAdd = await db.collection('hlpMapAnnotations').add(params)
		const hlpMapAnnotation = await db.collection('hlpMapAnnotations').doc(hlpMapAnnotationAdd.id).get()

		const hlpMapFeatureId = hlpMapAnnotation.data().hlpMapFeatureId
		const hlpMapFeature = await db.collection('hlpMapFeatures').doc(hlpMapFeatureId).get()
		const geoJSON = JSON.parse(hlpMapFeature.data().geoJSON)

		if (geometry) {
			geoJSON.geometry = geometry
		}

		const names = [];
		if (params.nameEN) {
			 names.push(params.nameEN)
		}
		if (params.nameAR) {
			 names.push(params.nameAR)
		}
		if (names.length > 0) {
			geoJSON.properties.name = names.join(' - ');			
		}


		await db.collection('hlpMapFeatures').doc(hlpMapFeatureId).update({ geoJSON: JSON.stringify(geoJSON) });
		const hlpMapFeature2 = await db.collection('hlpMapFeatures').doc(hlpMapFeatureId).get()

		return {
			annotation: hlpMapAnnotation,
			feature: hlpMapFeature2
		}

	} else if (action === 'hlp_map_annotation_update') {

		let geometry
		if (params.geometry) {
			geometry = params.geometry
			delete params.geometry
		}
	
		await db.collection('hlpMapAnnotations').doc(params.hlpMapAnnotationId).update(params)
		const hlpMapAnnotation = await db.collection('hlpMapAnnotations').doc(params.hlpMapAnnotationId).get()

		const hlpMapFeatureId = hlpMapAnnotation.data().hlpMapFeatureId
		const hlpMapFeature = await db.collection('hlpMapFeatures').doc(hlpMapFeatureId).get()
		const geoJSON = JSON.parse(hlpMapFeature.data().geoJSON)

		if (geometry) {
			geoJSON.geometry = geometry
		}

		const names = [];
		if (params.nameEN) {
			 names.push(params.nameEN)
		}
		if (params.nameAR) {
			 names.push(params.nameAR)
		}
		if (names.length > 0) {
			geoJSON.properties.name = names.join(' - ');			
		}

		await db.collection('hlpMapFeatures').doc(hlpMapFeatureId).update({ geoJSON: JSON.stringify(geoJSON) });
		const hlpMapFeature2 = await db.collection('hlpMapFeatures').doc(hlpMapFeatureId).get()

		return {
			annotation: hlpMapAnnotation,
			feature: hlpMapFeature2
		}
	}
	
	return true
}

export const CALL_API = Symbol('Call API')

const api = store => next => action => {
	const callAPI = action[CALL_API]

	if (typeof callAPI === 'undefined') {
		return next(action)
	}

	const [ requestType, successType, errorType ] = callAPI.types

	next({ type: requestType })

	return callApi(callAPI.db, callAPI.action, callAPI.params).then(
		response =>
			next({
				request: callAPI,
				response,
				type: successType
			}),
		error => {
			next({
				error: error,
				type: errorType
			})
		}
	)
}

export default api