import React from 'react'
import { NavLink } from "react-router-dom"
import styles from './Nav.module.css'
import logo from '../images/logo.svg'

export default function Nav(props) {

	return (
		<nav className={styles.nav}>
			<div>
				<div className={styles.logo}><img src={logo} alt="hlp logo" /></div>
				<ul>
					<li className={styles.homeLink}><NavLink to="/">HLP Maps</NavLink></li>
					{props.authUser && 
						<li><NavLink activeClassName={styles.active} to="/map">Map</NavLink></li>	
					}
					<li>
						<NavLink activeClassName={styles.active} to="/who-we-are">
							{props.language === 'en' && <span className="en">Who We Are</span>}
							{props.language === 'ar' && <span className="ar">من نحن</span>}
						</NavLink>
					</li>
					<li>
						<NavLink activeClassName={styles.active} to="/join">
							{props.language === 'en' && <span className="en">Join Us</span>}
							{props.language === 'ar' && <span className="en">انضم إلينا</span>}
						</NavLink>
					</li>
					<li><NavLink to="/faq">FAQ</NavLink></li>
				</ul>
				<ul className={styles.secondaryNav}>
					<li><NavLink to="/terms-and-conditions">Terms &amp; Conditions</NavLink></li>
					<li><NavLink to="/privacy-policy">Privacy Policy</NavLink></li>
					{props.authUser ? 
						<li><NavLink activeClassName={styles.active} to="/login" onClick={props.onLogout}>Logout</NavLink></li>
					:
						<React.Fragment>
							<li><NavLink activeClassName={styles.active} to="/login">Login/Signup</NavLink></li>
						</React.Fragment>
					}

					<li><a onClick={e => {
						e.preventDefault();
						props.onLanguageChange('en')
					}} href="/en">EN</a></li>
					<li><a onClick={e => {
						e.preventDefault();
						props.onLanguageChange('ar')
					}} href="/ar">عربى</a></li>
				</ul>
			</div>
		</nav>
	)
}
