import React from 'react'
import section from './Section.module.css'
import hero from '../images/hero-3.png'
export default function Join(props) {
	return (
		<section dir="auto">
			<div className={section.hero}>
				<img src={hero} alt="map" />
			</div>

			{props.language === 'en' && <JoinUsEn />}
			{props.language === 'ar' && <JoinUsAr />}
		</section>
	)
}

function JoinUsEn() {
	return (
		<React.Fragment>
			<article>
				<h1>Join Us</h1>
				<p>If you are a refugee or an internally displaces person,  or you are an activist or an expert in HLP rights and conflict contexts, we would love to hear from you!</p>
				<p>Drop us a message with you inquiry or to get in touch. </p>

			</article>
			<article>
				<h1>Volunteering</h1>
				<p>We always welcome those who would like to support our work through volunteering. There are different areas where you can volunteer with us:</p>
				<ol>
					<li>Support our mapathons on the ground- you can check through our social media if we are hosting a mapathon near you and volunteer to support us with event planning, mappers guidance and training, and hospitality.</li>
					<li>In instances where mapthons are happening near you and you would like to be part of these, you can attend and donate your laptop or tablet for a couple of hours to be used by mappers who don’t have any of these- with your presence of course.</li>
					<li>If you would like to partcipate in a mapthon happening remotely, you can always join us online- email us and we can make it happen</li>
				</ol>
			</article>
		</React.Fragment>
	)
}

function JoinUsAr() {
	return (
		<React.Fragment>
			<article>
				<h1>انضم إلينا</h1>
				<p>إذا كنت لاجئًا أو نازحًا داخليًا ، أو كنت ناشطًا أو خبيرًا في حقوق الإسكان والأرض والملكية وسياقات النزاع ، فنحن نحب أن نسمع منك!</p>
				<p>أرسل لنا رسالة مع استفسارك أو للتواصل معنا.</p>

			</article>
			<article>
				<h1>التطوع</h1>
				<p>نرحب دائمًا بأولئك الذين يرغبون في دعم عملنا من خلال التطوع. هناك مجالات مختلفة يمكنك التطوع معنا بها:</p>
				<ol>
					<li>ادعمنا بـ ماباثون على الأرض - يمكنك التحقق من خلال وسائل التواصل الاجتماعي الخاصة بنا إذا كنا نستضيف ماباثون بالقرب منك وتطوعنا لدعمنا في التخطيط للفعاليات وتوجيه مصممي الخرائط والتدريب والاستضافة</li>
					<li>في حال وجود ماباثون بالقرب منك وترغب في أن تكون جزءًا منها ، يمكنك الحضور والتبرع بجهاز الكمبيوتر المحمول أو الجهاز اللوحي لبضع ساعات لاستخدامها بواسطة راسمي الخرائط الذين ليس لديهم أي من هذه - مع إشرافك بالطبع.</li>
					<li>إذا كنت ترغب في المشاركة في ماباثون يحدث عن بعد ، يمكنك دائمًا الانضمام إلينا عبر الإنترنت - راسلنا عبر البريد الإلكتروني ويمكننا تحقيق ذلك</li>
				</ol>
			</article>
		</React.Fragment>
	)
}
