import React, { Component, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import MapTool from '../components/MapTool'

class HLPMapper extends Component {

	componentDidMount() {
	}

	render() {

		return (
			<MapTool {...this.props} />
		)
	}
}

HLPMapper.propTypes = {
	dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(state, ownProps) {

	const hlpMapFeatures = state.hlpMapFeatures
	const hlpMapAnnotations = state.hlpMapAnnotations
	const savingAnnotation = state.savingAnnotation
	const deletingAnnotation = state.deletingAnnotation

	const currentUserFeatures = []
	const otherUsersFeatures = []
	for (let hlpMapFeatureId in hlpMapFeatures) {
		const hlpMapFeature = hlpMapFeatures[hlpMapFeatureId]
	
		const geoJSON = Object.assign({}, hlpMapFeature.data.geoJSON);
		geoJSON.properties.currentUserCreated = (hlpMapFeature.data.uid === ownProps.authUser.uid)
		if (geoJSON.properties.currentUserCreated) {
			currentUserFeatures.push(geoJSON)
		} else {
			otherUsersFeatures.push(geoJSON)
		}
	}
	
	const currentUserFeatureCollection = {
		type: 'FeatureCollection',
		features: currentUserFeatures,
	}
	
	const otherUsersFeatureCollection = {
		type: 'FeatureCollection',
		features: otherUsersFeatures,
	}

	return {
		...ownProps,
		hlpMapFeatures,
		hlpMapAnnotations,
		currentUserFeatureCollection,
		otherUsersFeatureCollection,
		savingAnnotation,
		deletingAnnotation,
	}
}

export default connect(mapStateToProps)(HLPMapper)