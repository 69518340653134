
import React from 'react'
import section from './Section.module.css';
import hero from '../images/hero-5.png'
export default function FAQ() {
	return (
		<section>
			<div className={section.hero}>
				<img src={hero} alt="hero" />
			</div>
			<article>
				<h1>Terms and Conditions</h1>
			</article>

		</section>
	)
}
