import React, { useState, useEffect } from 'react'
import { useForm } from "react-hook-form";
import style from './AnnotationForm.module.css';
import forms from './Forms.module.css';
import features from '../data/features.json'


function getAgeOptions() {
	const ageOptions = [{ label: 'New', value: 'new' }]
	const startDecade = Math.floor(new Date().getFullYear() / 10) * 10;
	const endDecade = 1900
	for (let year = startDecade; year >= endDecade; year-=10) {
		ageOptions.push({ label: year + 's', value: year })
	}

	ageOptions.push({ label: 'Older than ' + endDecade, value: 'old' })
	ageOptions.push({ label: 'Ancient', value: 'ancient' })
	return ageOptions
}



export default function AnnotationForm(props) {

	let featureA;
	if (props.geometryType === 'Polygon') {
		featureA = 'area';
	} else if (props.geometryType === 'LineString') {
		featureA = 'line';
	} else if (props.geometryType === 'Point') {
		featureA = 'point';
	}


	const { handleSubmit, register, errors, setValue } = useForm()
	const onSubmit = values => {
		const hlpMapAnnotationId = (props.hlpMapAnnotation) ? props.hlpMapAnnotation.doc.id : undefined
		props.onSubmit(props.hlpMapFeatureId, hlpMapAnnotationId, {...values, featureA })		
	}

	const annotation = (props.hlpMapAnnotation) ? props.hlpMapAnnotation.data : undefined	

	const defaultFeatureB = (annotation && annotation.featureB) ? annotation.featureB : '';
	const defaultFeatureC = (annotation && annotation.featureC) ? annotation.featureC : '';
	const defaultFeatureD = (annotation && annotation.featureD) ? annotation.featureD : '';

	const [featureB, setFeatureB] = useState(defaultFeatureB)
	const [featureC, setFeatureC] = useState(defaultFeatureC)
	const [featureD, setFeatureD] = useState(defaultFeatureD)

	const handleFeatureChange = e => {
		if (e.target.name === 'featureB') {
			setFeatureB(e.target.value);
			setValue('featureC', '')
			setValue('featureD', '')
		} else if (e.target.name === 'featureC') {
			setFeatureC(e.target.value);
			setFeatureD('')
			setValue('featureD', '')
		} else if (e.target.name === 'featureD') {
			setFeatureD(e.target.value);
		}
		
	}

	const ageOptions = getAgeOptions()

	const condition = (annotation) ? annotation.condition : undefined
	const age = (annotation) ? annotation.age : undefined
	
	const nameEN = (annotation) ? annotation.nameEN : undefined
	const nameAR = (annotation) ? annotation.nameAR : undefined

	const descriptionEN = (annotation) ? annotation.descriptionEN : undefined
	const descriptionAR = (annotation) ? annotation.descriptionAR : undefined

	let saveButton = null;
	if (props.saved) {
		saveButton = <span><i className="far fa-check"></i> Saved</span>
	} else if (props.saving) {
		saveButton = <span><i className="fad fa-spinner"></i> Saving...</span>
	} else {
		saveButton = <span><i className="far fa-save"></i> Save</span>
	}

	let deleteButton = null;
	if (props.deleting) {
		deleteButton = <span><i className="far fa-spinner"></i> Deleting</span>
	} else {
		deleteButton = <span><i className="fas fa-trash-alt"></i> Delete</span>
	}
			

	console.log(featureA, featureB, featureC, featureD)

	return (
		<div className={style.form}>
			<form onSubmit={handleSubmit(onSubmit)}>		
				<h3>{(props.hlpMapAnnotation) ? 'Edit Your Annotation' : 'Add Your Annotation'}</h3>
				{featureA && <FeatureSelector name="featureB" featureValue={featureB} feature={features[featureA]} register={register} errors={errors} onChange={handleFeatureChange} />}
				{featureA && features[featureA].features && featureB && features[featureA].features[featureB].features && <FeatureSelector name="featureC" featureValue={featureC} feature={features[featureA].features[featureB]} register={register} errors={errors} onChange={handleFeatureChange} />}
				{featureA && features[featureA].features && featureB && features[featureA].features[featureB].features && featureC && features[featureA].features[featureB].features[featureC].features && (
					<FeatureSelector name="featureD" featureValue={featureD} feature={features[featureA].features[featureB].features[featureC]} register={register} errors={errors} onChange={handleFeatureChange} />
				)}


				<div className={style.formRow}>
					<label>Physical Condition<br />حالة المبنى </label>
					<select className={forms.selectElement} name="condition" defaultValue={condition} ref={register()}>
						<option value="">Select...</option>
						<option value="demolished">Demolished – تمت إزالته</option>
						<option value="destroyed">Destroyed – مدمر</option>
						<option value="damaged">Damaged – متضرر</option>
						<option value="good">Good – سليم</option>
					</select>
				</div>
		
				<hr />
		
				{props.geometryType === 'Polygon' && 
					<div className={style.formRow}>
						<label>Age<br />عمر المبنى</label>
						<select className={forms.selectElement} name="age" defaultValue={age} ref={register()}>
							<option value="">Select...</option>
							{ageOptions.map(ageOption => 
								<option key={`age_${ageOption.value}`} value={ageOption.value}>{ageOption.label}</option>
							)}
						</select>
					</div>
				}
				<hr/>

				<div className={`${style.formRow} ${style.name}`}>
					<label>Name (English)<br />الاسم</label>
					<input type="text" className={forms.inputElement} defaultValue={nameEN} name="nameEN" ref={register()} />
				</div>

				<hr/>
				<div className={`${style.formRow} ${style.name}`}>
					<label>Name (Arabic)<br />الاسم</label>
					<input type="text" className={forms.inputElement} defaultValue={nameAR} name="nameAR" ref={register()} dir="rtl" />
				</div>

				<hr/>

				<div className={`${style.formRow} ${style.description}`}>
					<label>Other Descriptive Details (English)<br />بعض المعلومات الأخرى </label>
					<textarea className={forms.textareaElement} defaultValue={descriptionEN} name="descriptionEN" ref={register()} />
				</div>

				<hr/>

				<div className={`${style.formRow} ${style.description}`}>
					<label>Other Descriptive Details (Arabic)<br />بعض المعلومات الأخرى </label>
					<textarea className={forms.textareaElement} defaultValue={descriptionAR} name="descriptionAR" ref={register()} dir="rtl" />
				</div>

				<div className={style.action}>
					<button 
						className={`${forms.buttonElement} ${forms.save}`} 
						type="submit"
					>
						{saveButton}						
					</button>

					{props.deletable && 
						<button 
							onClick={() => props.onDelete(props.hlpMapFeatureId, (props.hlpMapAnnotation) ? props.hlpMapAnnotation.doc.id : null)}
							className={`${forms.buttonElement} ${forms.delete}`} 
							type="button"
						>
							{deleteButton}
							
						</button>
					}
				</div>


			</form>
		</div>
	)

}


const FeatureSelector = props => {

	const options = [];

	for (let feature in props.feature.features) {
		options.push(<option key={feature} value={feature}>{props.feature.features[feature].label_en} – {props.feature.features[feature].label_ar}</option>)
	}

	return (
		<React.Fragment>
			<div className={style.formRow}>
				<label>{props.feature.label_en}<br />{props.feature.label_ar}</label>
				<select className={forms.selectElement} name={props.name} defaultValue={props.featureValue} onChange={props.onChange} ref={props.register({ required: true })}>
					<option value="">Select...</option>
					{options}
				</select>
				{ props.errors[props.name] && <div className={forms.required}>Required</div> }
			</div>
			<hr />
		</React.Fragment>
	)
}



// function AnnotationForm1(props) {


// 	const annotation = (props.hlpMapAnnotation) ? props.hlpMapAnnotation.data : undefined
	
// 	const defaultFeatureGroup = (props.highwayRoadType) ? 'highway' : undefined
// 	const defaultFeatureSubGroup = (props.highwayRoadType) ? 'roads' : 'list'
// 	const defaultFeatureItem = (props.highwayRoadType) ? props.highwayRoadType : undefined
	
// 	const [featureGroup, setFeatureGroup] = useState((annotation) ? annotation.featureGroup : defaultFeatureGroup);
// 	const [featureSubGroup, setFeatureSubGroup] = useState((annotation && annotation.featureSubGroup) ? annotation.featureSubGroup : defaultFeatureSubGroup);
// 	const [featureItem, setFeatureItem] = useState((annotation) ? annotation.featureItem : defaultFeatureItem);

// 	const { handleSubmit, register, errors } = useForm()
// 	const onSubmit = values => {
// 		const hlpMapAnnotationId = (props.hlpMapAnnotation) ? props.hlpMapAnnotation.doc.id : undefined
// 		props.onSubmit(props.hlpMapFeatureId, hlpMapAnnotationId, values)		
// 	}

// 	const handleFeatureGroupChange = e => {
// 		setFeatureGroup(e.target.value);
// 		if (featureGroups[e.target.value]) {
// 			const subGroups = Object.keys(featureGroups[e.target.value].sections)
// 			if (subGroups.length === 1) {
// 				setFeatureSubGroup(subGroups[0])
// 			} else {
// 				setFeatureSubGroup(undefined)
// 			}
// 		} else {
// 			setFeatureSubGroup(undefined)
// 		}
// 	}
	
// 	const handleFeatureSubGroupChange = e => {
// 		setFeatureSubGroup(e.target.value)
// 	}
	
// 	const handleFeatureItemChange = e => {
// 		setFeatureItem(e.target.value)
// 	}
	
	



// 	return (
// 		<div className={style.form}>
// 			<form onSubmit={handleSubmit(onSubmit)}>		
// 				<h3>{(props.hlpMapAnnotation) ? 'Edit Your Annotation' : 'Add Your Annotation'}</h3>

// 				<FeatureGroupSelector featureGroup={featureGroup} onChange={handleFeatureGroupChange} register={register} errors={errors} />
// 				{featureSubGroup !== 'list' && <FeatureSubGroupSelector featureGroup={featureGroup} featureSubGroup={featureSubGroup} onChange={handleFeatureSubGroupChange} register={register} errors={errors} />}
// 				<FeatureSelector featureGroup={featureGroup} featureSubGroup={featureSubGroup} featureItem={featureItem} onChange={handleFeatureItemChange} register={register} errors={errors} />

// 			</form>
// 		</div>
// 	)
// }
