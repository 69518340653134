import React from 'react'
import section from './Section.module.css';
import hero from '../images/hero-5.png'
export default function FAQ() {
	return (
		<section>
			<div className={section.hero}>
				<img src={hero} alt="hero" />
			</div>
			<article>

				<h1>Frequently Asked Questions</h1>

				<div>
					<h2>What does HLP Maps mean?</h2>
					<p>HLP is short for Housing, lands, and property. HLP maps is a platform that has the tools to help refugees and internally displaced persons in documenting their rights in their properties. These tools include maps layers, satellite imageries, and customized forms to document their ownership/tenancy rights.</p>

					<h2>What does HLP Maps do</h2>
					<p>HLP Maps helps Refugees and Internally displaced people in documetning their properties with the help of the maps and old satellite imagery. Users can add evidence of ownership or tenancy, and we will help verify their documentation, even if incomplete, to support any future claim of property rights.</p>

					<h2>What is the purpose of mapping?</h2>
					<p>Mapping will help refugees and IDPs in navigating the satellite imagery easily, and will help keeping the old landmarks names the same, protecting it from any demolishing or memory loss.</p>

					<h2>How?</h2>
					<p>To map cities, we will ask local communities and residents to map it.</p>

					<h2>What comes after mapping?</h2>
					<p>Claiming the properties. We are working with local NGOs and community mobilisers to organize the claming process.</p>

					<h2>I can't go back to Syria, how will this help me?</h2>
					<p>Many people are sadly in the same position. We are providing the tool to help secure your right in your property, no matter what your status is regarding going back to Syria.</p>

					<h2>Any more questions?</h2>
					<p><a href="mailto:info@hlpmaps.org">Contact us</a></p>

				</div>
			</article>
		</section>
	)
}
