import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
// import { createLogger } from 'redux-logger'
import rootReducer from '../reducers'
import api from '../middleware/api'

// const loggerMiddleware = createLogger()

export default function configureStore(preloadedState) {
	return createStore(
		rootReducer,
		preloadedState,
		// applyMiddleware(thunkMiddleware, api, loggerMiddleware)
		applyMiddleware(thunkMiddleware, api)
	)
}
