import React from 'react'
import section from './Section.module.css';
import hero from '../images/hero-5.png'
export default function FAQ() {
	return (
		<section>
			<div className={section.hero}>
				<img src={hero} alt="hero" />
			</div>
			<article>
				<h1>Privacy Policy</h1>

				<p>HLP Maps is an independent community interest company which is registered in England as a company limited by guarantee (company number 12939081). The registered office is located at International House, 12 Constance Street, London, E16 2DQ.</p>
				<p>HLP Maps is committed to protecting and respecting your rights. This policy sets out the basis on which any personal data we collect from you, or that you provide to us, is used.</p>

				<h2>Glossary</h2>
				<ul>
					<li>Partner: an organisation with a contract with us permitting significant access to our services and content.</li>
					<li>Data Controller: a person/organisation responsible for managing personal data under law.</li>
					<li>Data Processor: a person/organisation responsible for handling personal data under law.</li>
					<li>Claimant: Refugees or internally displaced who are using our platform to claim their property and document their rights using our tools </li>
				</ul>

				<h2>Data collection</h2>
				<p>HLPM is the sole owner of information collected on this site. We will not sell, share, or rent this information to others in ways different from what is disclosed in this statement. Our website offers several opportunities for visitors to register for informational electronic mailings. These registration forms are often linked to HLPM mailing lists and require users to give contact information (such as name and email address). We use this contact information to send users information about HLPM work, news updates, and new initiatives. Users may opt-out of receiving future mailings at any time.</p>
				<p>We will collect basic personal data about you, including:</p>

				<ol>
					<li>
						Account Information: If you sign up for or authenticate into an account with us, we may collect information that you provide to us in connection with setting up the account, such as your username, name, email address and, for an account you authenticate into, role. Further, in the course of using your account, you may provide us with additional information through your communications with us. 
						<ul>
							<li>Name</li>
							<li>Phone number and email</li>
							<li>Images (with consent)</li>
							<li>Email correspondence</li>
						</ul>
					</li>	
					<li>
						Website Logs and Cookies: Whenever you visit or interact with our website, we automatically collect certain information about your browser and your interaction with our website, including (a) IP address, (b) browser and device type, (c) operating system, (d) referring web page, (e) the date and time of page visits, and (f) the pages accessed on our websites. We also use cookies and similar technologies to recognize and improve your use of our websites and, for accounts a user authenticated into, to retain records associated with each user, and you will not be able to access certain parts of our websites, including those that require a login, unless your device accepts cookies from us.
					</li>
				</ol>

				<h2>Sharing and Usage</h2>
				<p>The data we collect will be used to send communications that you have consented to receive.</p>
				<p>We use Google Analytics to measure anonymous user activity that helps us understand our users' behaviour and to improve our services. No personal data is collected by us when we use Google Analytics and you should refer to the Google privacy policy for more information.</p>
				<p>We will never share, sell, or rent your personal information with third parties for their promotional use. Occasionally, we enter into contracts with third parties so that they can assist us in servicing you. The contracts prohibit them from using any of your personal information for their own purposes, and they are required to maintain the confidentiality of the information we provide to them. We may disclose or report personal information in limited circumstances where we believe in good faith that disclosure is required under the law. </p>

				<h2>Use of Web Technologies</h2>
				<p>We use a variety of technologies on our site. Among these are cookies; a piece of information that our websites provide to your browser when you visit our sites. Cookies allow us to verify the login status of customers using services linked directly with our website, track point of entry to point of registration for those users participating in our email signup programs, and to track and measure the success of a particular email blast. Cookies also allow us to track overall site usage and determine areas users prefer, enabling us to make your visit to our website easier by recognizing you when you return and helping to provide you with a customized experience. Usage of a cookie is in no way linked to any personally identifiable information while on our site. </p>
				<p>We also keep track of activity on our website via log files stored on our web servers. We collect information such as IP address, browser type and version, and pages you view. We also keep track of how you got to our site and any links you click on to leave our site. We use your website activity to assist us in offering you a personalized Web experience, assist you with technical support, diagnose problems with our server, administer our websites, and to tailor our informational offerings to you.</p>

				<h2>Security</h2>
				<p>We take every precaution to protect the confidentiality and security of your personal information by using industry-recognized security safeguards such as firewalls, coupled with carefully developed security procedures to protect your information from loss, misuse, or unauthorized alteration.</p>
				<p>HLPM takes care to reinforce the importance of our website visitors’ security and privacy among our employees. Our employees are trained and required to safeguard your information and, using physical, electronic, and procedural safeguards, we restrict access to personal information to those employees and agents for business purposes only.</p>

				<h2>Notification of Changes</h2>
				<p>If we decide to change our privacy policy, we will post those changes on the HLPM website, so our users are always aware of what information we collect, how we use it, and under what circumstances, if any, we disclose it.</p>
				<p>We reserve the right to update this privacy policy at any time, and we will notify you if we make any substantial updates. We may also notify you in other ways from time to time about the processing of your personal information.</p>

			</article>
		</section>
	)
}
