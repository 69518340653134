import React, { useState } from 'react'
import style from './LayerToggles.module.css';


const layers = {
	streets: [
		'azaz-streets'
	],
	poi: ['poi-label'],
	strikes: []
}


const layerInfo = {
	streets: {
		on: true,
		label: 'OSM Streets',
	}
}

export default function LayerToggles(props) {

	const [layerToggles, setLayerToggles] = useState(layerInfo)

	const handleLayerToggle = (e) => {
		const layerKey = e.target.value
		const layerToggle = layerToggles[layerKey]

		const layerSet = layers[layerKey]
		layerSet.forEach(layer => {
			// props.onLayerToggle(layer, !layerToggle.on)
			props.mapboxMap.setLayoutProperty(layer, 'visibility', !layerToggle.on ? 'visible' : 'none');
		});

		const lt = Object.assign({}, layerToggles);
		lt[layerKey].on = !lt[layerKey].on
		setLayerToggles(lt)
	}

	return (
		<div className={style.layerControls}>
			<h3>Map Layer Controls</h3>
			{Object.keys(layerToggles).map(layerKey => {

				const layerToggle = layerToggles[layerKey]

				return (
					<div key={`layer_toggle_${layerKey}`} className={style.layerToggle}>
						<label 
							className={(layerToggle.on) ? style.on : style.off}

						>
							<input 
								defaultChecked
								value={layerKey}
								onClick={handleLayerToggle}
								type="checkbox"
							/>
							<span className={`${style.round} ${style.slider}`}></span>
							
						</label>
						<div className={style.layerToggleLabel}>
							<div>{layerToggle.label}</div>
							<div className={style.layerToggleStatus}>{(layerToggle.on) ? 'on' : 'off'}</div>
						</div>

					</div>
				)
			})}
		</div>
	)	
}