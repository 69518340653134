import styles from './ToolTip.module.css';

export default function ToolTip(props) {
	// 'draw_line_string', 'draw_polygon', 'draw_point'

	return (
		<div className={styles.tooltip}>
			{props.mode === 'draw_line_string' && (
				<div>
					<em>To draw a road, click on the map to start drawing. Draw as many points as you need for the length of the road – then click the last point to finish.</em>
				</div>
			)}

			{props.mode === 'draw_polygon' && (
				<div>
					<em>To draw a building, click on the map to start. To complete the shape, click on the first point.</em>
				</div>
			)}

			{props.mode === 'draw_point' && (
				<div>
					<em>To mark a single point, click anywhere on the map.</em>
				</div>
			)}
		</div>
	)
}