import React from 'react'
import hero from '../images/hero.png'
import section from './Section.module.css'

export default function Home(props) {
	return (
		<section>
			<div className={section.hero}><img src={hero} alt="map" /></div>

			{props.language === 'en' && <HomeEn />}
			{props.language === 'ar' && <HomeAr />}

		</section>
	)
}


function HomeEn() {
	return (
		<article>
			<div>
				<h1>HLP Maps</h1>
				<p>Properties and houses in conflict and war areas are more vulnerable to threats, confiscations, and destruction. While communities flee for safety and sheltering, or in many cases are forcibly displaced, their houses and properties are left unattended and more exposed to threat, especially when they lack evidence or authority to protect the rights to these properties.</p>
				<p>Documenting the properties rights in such difficult circumstances require an innovative solution. This project will aim to address this issue and start collecting evidence of ownership and tenancy using satellite imagery and other alternative evidence of ownership.</p>
				<p>The project focuses on displaced and refugees, whose properties are more vulnerable in front of different threats. The project aims of creating a bottom-up documentation system for these properties that can be used for legal and humanitarian intervention in the future. At the heart of HLP Maps vision is the emphasis on empowerment and agency for displaced and refugees as they act, verify, and exchange information about their homes and actively seek to document their rights.</p>
			</div>
		</article>
	)
}

function HomeAr() {
	return (
		<article>
			<div>
				<h1>HLP Maps</h1>
				<p>تكون المنازل والملكيات الخاصة في مناطق الصراع والحرب أكثر عرضة للتهديدات والمصادرة والتدمير من غيرها. عندما تنزح المجتمعات بحثًا عن الأمان والمأوى، أو في كثير من الحالات عندما يتم تهجيرهم قسرًا، تُترك منازلهم وممتلكاتهم دون رقابة وتصبح أكثر عرضة للتهديد، خاصة عندما يفتقرون إلى الإثباتات أو الأدلة أو السلطة لحماية حقوقهم في هذه الممتلكات.</p>
				<p>يتطلب توثيق حقوق الملكية في مثل هذه الظروف الصعبة حلاً مبتكرًا. يهدف هذا المشروع إلى معالجة هذه المشكلة والبدء في جمع أدلة الملكية والإيجار باستخدام صور الأقمار الصناعية وغيرها من الأدلة البديلة عن وثيقة الملكية.</p>
				<p>يركز المشروع على النازحين واللاجئين، والذين تكون ممتلكاتهم أكثر عرضة لمختلف التهديدات. يهدف المشروع إلى إنشاء نظام توثيق جذري ينطلق من مجتمعات النازحين واللاجئين ليتمكنوا من استخدامه للتدخل القانوني والإنساني في المستقبل. إن رؤية خرائط الملكية العقارية في هذا المشروع هو التركيز على تمكين النازحين واللاجئين وإعطائهم الأدوات اللازمة لتكون لديهم الوكالة أثناء قيامهم بالتصرف والتحقق وتبادل المعلومات حول منازلهم أثناء سعيهم لتوثيق حقوقهم.</p>
			</div>
		</article>
	)
}


