import React, { useState, useRef } from 'react'
import { Redirect, Link } from 'react-router-dom'
import style from './Login.module.css'
import forms from './Forms.module.css'
import section from './Section.module.css'
import hero from '../images/hero-4.png'

export default function Login(props) {

	const loginEmailRef = useRef(null);
	const loginPasswordRef = useRef(null);

	const registerEmailRef = useRef(null);
	const registerPasswordRef = useRef(null);

	const signIn = (auth) => {
		auth.then(result => {
			// console.log(result)
		}).catch((error) => {
			alert(error.message)
		});	
	}

	const loginWithFacebook = () => {
		const provider = new props.firebase.auth.FacebookAuthProvider();
		signIn(props.firebase.auth().signInWithPopup(provider))
	}

	const loginWighGoogle = () => {
		const provider = new props.firebase.auth.GoogleAuthProvider();
		signIn(props.firebase.auth().signInWithPopup(provider))
	}

	const loginWighEmail = (email, password) => {
		signIn(props.firebase.auth().signInWithEmailAndPassword(email, password))
	}

	const handleLoginFormSubmit = (e) => {
		e.preventDefault()
		const email = loginEmailRef.current.value
		const password = loginPasswordRef.current.value
		loginWighEmail(email, password)
	}

	const handleRegisterFormSubmit = (e) => {
		e.preventDefault()
		const email = registerEmailRef.current.value
		const password = registerPasswordRef.current.value

		props.firebase.auth().createUserWithEmailAndPassword(email, password).then((userCredential) => {
			const user = userCredential.user;
			user.sendEmailVerification()		
		})
		.catch((error) => {
			const errorMessage = error.message
			alert(errorMessage)
		});
	}

	if (props.authUser) {
		if (props.isEmailVerified()) {
			return <Redirect to="/map" />
		}
	}

	let verificationMessage = null
	let loginForm = null;

	if (props.authUser && !props.isEmailVerified()) {
		return <Redirect to="/verify-email" />
	} else {
		loginForm = (
			<div className={style.loginSignup}>
				<div>
					<h1>Login</h1>
					<p>You can directly login if you have a Google account. Otherwise you can sign up with an email address and log using that.</p>

					<div className={style.row}>
						<button className={`${forms.buttonElement} ${forms.google}`} onClick={loginWighGoogle}><i className="fab fa-google"></i> Login with Google</button>
					</div>

					<div className={style.row}>
						<button className={`${forms.buttonElement} ${forms.facebook}`} onClick={loginWithFacebook}><i className="fab fa-facebook"></i> Login with Facebook</button>
					</div>
					
					<hr/>
					<div className={style.row}>
						<h4>Login with Email</h4>
						<form onSubmit={handleLoginFormSubmit}>
							<div>
								<input className={forms.largeInputElement} ref={loginEmailRef} type="email" name="email" placeholder="Email" />
							</div>
							<div>
								<input className={forms.largeInputElement} ref={loginPasswordRef} type="password" name="password" placeholder="Password" />
							</div>
							<div>
								<button className={forms.buttonElement} type="submit">Login</button>
							</div>
						</form>
					</div>
				</div>
				<div>
					<h1>Signup</h1>

					{!props.authUser && (
						<form onSubmit={handleRegisterFormSubmit}>
							<div>
								<input className={forms.largeInputElement} ref={registerEmailRef} type="email" name="email" placeholder="Email" />
							</div>
							<div>
								<input className={forms.largeInputElement} ref={registerPasswordRef} type="password" name="password" placeholder="Password" />
							</div>
							<div>
								<button className={forms.buttonElement} type="submit">Sign Up</button>
							</div>
						</form>
					)}
				</div>
			</div>
		)
	 }

	return (
		<section>
			<div className={section.hero}>
				<img src={hero} alt="hero" />
			</div>
			<article className={style.login}>
				{verificationMessage}
				{loginForm}
			</article>
		</section>
	)
}

