import { CALL_API } from '../middleware/api'

export const HLP_MAP_FEATURES_REQUEST = 'HLP_MAP_FEATURES_REQUEST'
export const HLP_MAP_FEATURES_SUCCESS = 'HLP_MAP_FEATURES_SUCCESS'
export const HLP_MAP_FEATURES_FAILURE = 'HLP_MAP_FEATURES_FAILURE'

export const HLP_MAP_FEATURE_ADD_REQUEST = 'HLP_MAP_FEATURE_ADD_REQUEST'
export const HLP_MAP_FEATURE_ADD_SUCCESS = 'HLP_MAP_FEATURE_ADD_SUCCESS'
export const HLP_MAP_FEATURE_ADD_FAILURE = 'HLP_MAP_FEATURE_ADD_FAILURE'

export const HLP_MAP_FEATURE_UPDATE_REQUEST = 'HLP_MAP_FEATURE_UPDATE_REQUEST'
export const HLP_MAP_FEATURE_UPDATE_SUCCESS = 'HLP_MAP_FEATURE_UPDATE_SUCCESS'
export const HLP_MAP_FEATURE_UPDATE_FAILURE = 'HLP_MAP_FEATURE_UPDATE_FAILURE'

export const HLP_MAP_FEATURE_DELETE_REQUEST = 'HLP_MAP_FEATURE_DELETE_REQUEST'
export const HLP_MAP_FEATURE_DELETE_SUCCESS = 'HLP_MAP_FEATURE_DELETE_SUCCESS'
export const HLP_MAP_FEATURE_DELETE_FAILURE = 'HLP_MAP_FEATURE_DELETE_FAILURE'

export const HLP_MAP_ANNOTATION_ADD_REQUEST = 'HLP_MAP_ANNOTATION_ADD_REQUEST'
export const HLP_MAP_ANNOTATION_ADD_SUCCESS = 'HLP_MAP_ANNOTATION_ADD_SUCCESS'
export const HLP_MAP_ANNOTATION_ADD_FAILURE = 'HLP_MAP_ANNOTATION_ADD_FAILURE'

export const HLP_MAP_ANNOTATION_UPDATE_REQUEST = 'HLP_MAP_ANNOTATION_UPDATE_REQUEST'
export const HLP_MAP_ANNOTATION_UPDATE_SUCCESS = 'HLP_MAP_ANNOTATION_UPDATE_SUCCESS'
export const HLP_MAP_ANNOTATION_UPDATE_FAILURE = 'HLP_MAP_ANNOTATION_UPDATE_FAILURE'

function _fetchFeatures(db, params) {
	return {
		[CALL_API]: {
			db: db,
			action: 'hlp_map_features_fetch',
			params: params,
			types: [HLP_MAP_FEATURES_REQUEST, HLP_MAP_FEATURES_SUCCESS, HLP_MAP_FEATURES_FAILURE]
		}
	}
}

export const fetchFeatures = (db, params) => (dispatch, getState) => {
	return dispatch(_fetchFeatures(db, params))
}


function _addFeature(db, params) {
	return {
		[CALL_API]: {
			db: db,
			action: 'hlp_map_feature_add',
			params: params,
			types: [HLP_MAP_FEATURE_ADD_REQUEST, HLP_MAP_FEATURE_ADD_SUCCESS, HLP_MAP_FEATURE_ADD_FAILURE]
		}
	}
}

export const addFeature = (db, params) => (dispatch, getState) => {
	console.log(db, params)
	return dispatch(_addFeature(db, params))
}

function _updateFeature(db, params) {
	return {
		[CALL_API]: {
			db: db,
			action: 'hlp_map_feature_update',
			params: params,
			types: [HLP_MAP_FEATURE_UPDATE_REQUEST, HLP_MAP_FEATURE_UPDATE_SUCCESS, HLP_MAP_FEATURE_UPDATE_FAILURE]
		}
	}
}

export const updateFeature = (db, params) => (dispatch, getState) => {
	return dispatch(_updateFeature(db, params))
}

function _deleteFeature(db, params) {
	return {
		[CALL_API]: {
			db: db,
			action: 'hlp_map_feature_delete',
			params: params,
			types: [HLP_MAP_FEATURE_DELETE_REQUEST, HLP_MAP_FEATURE_DELETE_SUCCESS, HLP_MAP_FEATURE_DELETE_FAILURE]
		}
	}
}

export const deleteFeature = (db, params) => (dispatch, getState) => {
	return dispatch(_deleteFeature(db, params))
}












function _addAnnotation(db, params) {
	return {
		[CALL_API]: {
			db: db,
			action: 'hlp_map_annotation_add',
			params: params,
			types: [HLP_MAP_ANNOTATION_ADD_REQUEST, HLP_MAP_ANNOTATION_ADD_SUCCESS, HLP_MAP_ANNOTATION_ADD_FAILURE]
		}
	}
}

export const addAnnotation = (db, params) => (dispatch, getState) => {
	return dispatch(_addAnnotation(db, params))
}

function _updateAnnotation(db, params) {
	return {
		[CALL_API]: {
			db: db,
			action: 'hlp_map_annotation_update',
			params: params,
			types: [HLP_MAP_ANNOTATION_UPDATE_REQUEST, HLP_MAP_ANNOTATION_UPDATE_SUCCESS, HLP_MAP_ANNOTATION_UPDATE_FAILURE]
		}
	}
}

export const updateAnnotation = (db, params) => (dispatch, getState) => {
	return dispatch(_updateAnnotation(db, params))
}
